.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.ph {
  position: relative;
  width: 256px;
}

@keyframes typing {
  0% { width: 0% }
  100% { width: 100% } /* 100% - padding*/
}

.bubble {
  width: 100%;
  white-space: nowrap; /* Keeps the content on a single line */
	background-color: #000000;
	border-radius: 5px;
  color: #D7C4BB;
  font-family: 'Cherry Swash', cursive;
  text-align: center;
	padding: 10px 10px;
  
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*color: rgba(255,255,255,0.8); */
}

.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.floater {
  font-family: 'Cherry Swash', cursive;
  font-size: 20px;
  position:absolute;
  left: 1%;
  top: 1%;
  opacity: 0;
  transition: all 0.8s ease-out;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.floater:hover {
  opacity: 1;
}
