@keyframes changeSize {
  from {
    transform: scale(0.85, 0.9);
  }
  to {
    transform: scale(1.0, 1.0);
  }
}

.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}

.icon {
  height: 200px;
  width: 200px;
  animation-duration: 1s;
  animation-name: changeSize;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.icon:hover {
  animation-duration: 0.5s;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*color: rgba(255,255,255,0.8); */
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

@keyframes typing {
  from { width: 0% }
  to { width: 100% } /* 100% - padding*/
}

.bubble {
  width: 100%;
  white-space: nowrap; /* Keeps the content on a single line */
	background-color: #000000;
	border-radius: 5px;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  color: #D7C4BB;
  font-family: 'Cherry Swash', cursive;
  text-align: center;
	padding: 10px 10px;
}

